<template>
  <div>
    <search-traffic-pool @submit="submitSearch" />

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 'max-content' }"
      row-key="id"
    >
      <div slot="name" slot-scope="name, record">
        <div v-if="isHasShowPermission">
          <router-link :to="{ name: 'agency_traffic_pool_info', params: { id: agentId, traffic_pool_id: record.id }}">
            {{ name }}
          </router-link>
        </div>
        <div v-else> {{ name }} </div>
      </div>
    </a-table>

    <pagination
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/index'
import { findTrafficPools } from '@/api/traffic_pool'
import { hasPermission } from '@/utils/permission'
import SearchTrafficPool from '@/views/traffic_pools/Search'

export default {
  name: 'TrafficPoolList',
  components: {
    Pagination,
    SearchTrafficPool
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    },

    columns() {
      return [
        {
          title: '流量池名称',
          dataIndex: 'name',
          fixed: 'left',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '流量池编号',
          dataIndex: 'no'
        },
        {
          title: '运营商种类',
          dataIndex: 'carrier_type'
        },
        {
          title: '本周期可用总流量',
          dataIndex: 'total_traffic'
        },
        {
          title: '本周期已用流量',
          dataIndex: 'used_traffic'
        },
        {
          title: '本周期剩余流量',
          dataIndex: 'left_traffic'
        },
        {
          title: '使用进度',
          dataIndex: 'usage_progress'
        },
        {
          title: '卡号总数',
          dataIndex: 'card_count'
        },
        {
          title: '可用卡数',
          dataIndex: 'available_card_count'
        }
      ]
    },

    isHasShowPermission() {
      return hasPermission('customer/traffic_pools/agency/by_top_agent/show')
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findTrafficPools(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        this.data = res.data
        this.pagination.total_count = res.pagination.total_count
        this.loading = false
      })
    }
  }
}
</script>

